const studies = [
    {
        date: "2018 - 2025",
        name:"Analista en Tecnologías de la Información", 
        university:"Universidad ORT",
        description: "Terminando el cuarto semestre"
    },
    {
        date: "2022",
        name:"JavaScript Algorithms and Data Structures", 
        university:"freeCodeCamp",
        description: "Finalizado"
    },
    {
        date: "2021",
        name:"Programación Fullstack", 
        university:"Senpai Academy",
        description: "Finalizado"
    },
    {
        date: "2017",
        name:"Tester de Software", 
        university:"CES",
        description: "Finalizado"
    },
    {
        date: "2015-2016",
        name:"Programador Ruby", 
        university:"Fundación Forge",
        description: "Finalizado"
    },
    {
        date: "2016",
        name:"Analista Genexus", 
        university:"Genexus",
        description: "Finalizado"
    },
    {
        date: "2012",
        name:"Técnico en Reparación de Pc y Redes", 
        university:"BIOS",
        description: "Finalizado"
    },
    {
        date: "2012",
        name:"Taller Linux", 
        university:"BIOS",
        description: "Finalizado"
    },
    {
        date: "2012",
        name:"Taller Seguridad Informática", 
        university:"BIOS",
        description: "Finalizado"
    },
];

export default studies;