import React from "react";

const PersonalData = ({ classString,  title, value}) => {
    function calcularEdad() {
        const fechaActual = new Date();
        const añoActual = fechaActual.getFullYear();
        const mesActual = fechaActual.getMonth();
        const diaActual = fechaActual.getDate();

        const añoNacimiento = 1992;
        const mesNacimiento = 11;
        const diaNacimiento = 24;

        let edad = añoActual - añoNacimiento;

        if (mesActual < mesNacimiento || (mesActual === mesNacimiento && diaActual < diaNacimiento)) {
            edad--;
        }

        return edad;
    }
    
    return (
        <li className={ "personal-info__option " + classString } >
            <span className="personal-info__title">{ title }</span>
            {classString === "personal-info__option--first"
                ? <span className="personal-info__value">{ calcularEdad() }</span>
                : <span className="personal-info__value">{ value }</span>
            }
        </li>
    );
}

export default PersonalData;