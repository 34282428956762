const studies = [
    {
        date: "2021",
        name:"Inglés A2 Key", 
        university:"Dickens",
        description: "Preparando examen internacional B2"
    },
    {
        date: "2016",
        name:"Portugués Avanzado", 
        university:"Instituto Accent",
        description: "Finalizado"
    },
];

export default studies;