const experiences = [
    {
        position: "Tester de Software Sr.",
        company: "Apraful",
        tasks: [ "Testing Funcional (Manual)", "Testing de aplicaciones Web y WS (Soap y Rest)" ],
        startDate: '03/2022',
        endDate: 0
    },
    {
        position: "Desarrollador",
        company: "2innovateIT",
        tasks: [ "Desarrollo con JavaScript", "SQL Server", "Utilización de herramientas de desarrollo interno" ],
        startDate: '12/2021',
        endDate: '02/2022'
    },
    {
        position: "Desarrollador Freelance (a término)",
        company: "The Good Guys",
        tasks: [ "Desarrollo con JavaScript", "Maquetación con HTML5, CSS3" ],
        startDate: '11/2021',
        endDate: '12/2021'
    },
    {
        position: "Tester de Software",
        company: "Easy Consultores",
        tasks: [ "Testing funcional de sistemas bancarios", "Desarrollo de WS en Java", "Configuraciones en bases de datos" ],
        startDate: '05/2017',
        endDate: '10/2021'
    },
];

export default experiences;