import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

import './header.css';
import './responsive.css';
import logo from '../../Recursos/logo.png';

import BtnOptions from "./BtnOptions";

const Header = ({ isAsideVisible, toggleAside }) => {

    const handleScroll = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    // Crear referencias a los elementos
    const iconRef = useRef(null);

    // Efecto para manejar la visibilidad del menú y el icono en función del estado
    useEffect(() => {
        // const aside = asideRef.current;
        const icon = iconRef.current;

        if (isAsideVisible) {
            // aside.classList.add("layout__aside--visible");
            icon.classList.remove("fa-bars");
            icon.classList.add("fa-xmark");
        } else {
            // aside.classList.remove("layout__aside--visible");
            icon.classList.remove("fa-xmark");
            icon.classList.add("fa-bars");
        }
    }, [isAsideVisible]);

    // Efecto para manejar el redimensionamiento de la ventana
    useEffect(() => {
        const handleResize = () => {
            const size = parseInt(document.body.clientWidth);
            if (size <= 1060) {
                // setIsAsideVisible(false); // Ocultar el menú si el tamaño es menor o igual a 1060px
            }
        };

        // Agregar el evento de redimensionamiento
        window.addEventListener("resize", handleResize);

        // Limpiar el evento al desmontar el componente
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <header id='header' className='navbar__layout'>
            {/* Logo */}
            <div className="navbar__logo">
                <Link to="/" preventScrollReset={false} onClick={handleScroll} className="navbar__link-logo" draggable="false">
                    <img src={logo} alt="Logo de la página web" className="navbar__logo-img" draggable="false" />
                </Link>
            </div>

            {/* Navegación */}
            <nav className='navbar__nav'>
                <ul className="nav__list">
                    <li className="nav__item">
                        <BtnOptions btnName="INICIO" to="/" />
                    </li>
                    <li className="nav__item">
                        <BtnOptions btnName="SOBRE MI" to="/about-me" />
                    </li>
                    <li className="nav__item">
                        <BtnOptions btnName="ESTUDIOS" to="/education" />
                    </li>
                    <li className="nav__item">
                        <BtnOptions btnName="EXPERIENCIA" to="/experience" />
                    </li>
                    <li className="nav__item">
                        <BtnOptions btnName="CONTACTO" to="/contact" />
                    </li>
                </ul>
            </nav>

            {/* Menu responsive */}
            <div className="layout__menu--toggle" onClick={toggleAside}>
                <i ref={iconRef} className="toggle__icon fa-solid"></i>
            </div>
        </header>
    );
}

export default Header;