import React from "react";

import './timelineExperience.css';
import './responsive.css';

const TimelineExperience = ({ position, company, tasks, startDate, endDate, i }) => {

    i = i + 10;

    return(
        <>
            <section className='timeline__content timeline__content--experience' key={ i } >
                <div className='content__data' key={ i + 1 } >
                    <p className="data__position">{ position }</p>
                    <p className="data__company"> Empresa: { company }</p>
                    {endDate > 0 || isNaN(endDate)
                        ? <p className="data__date">
                                <span>Desde: </span> { startDate } - <span>Hasta: </span> { endDate }
                          </p>
                        : <p className="data__date">
                                <span>Desde: </span> {startDate} - <span>Hasta la actualidad</span>
                          </p>
                    }
                    <div className="data__tasks" key={ i + 2 } >
                        <p className="tasks__title">Principales tareas: </p>
                        <ul className="tasks__list">
                            {
                                tasks.map(function (task, index) {
                                    index = index + 50;
                                    return <li key={ index } className="list__item"><span>- </span>{ task }</li>
                                })
                            }
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TimelineExperience;