import React from 'react';

import '../lib/styles/scroll.css';
import './AsideMenu.css';

import BtnOptions from "../Header/BtnOptions";

const AsideMenu = ({ toggleAside }) => {
    return (
        <aside className="layout__aside">
            <section className='aside__personal-info--responsive'>
                <div className="personal-info__general--responsive">
                    <h2 className="personal-info__name--responsive">Jonatan Porteiro</h2>
                    <h3 className="personal-info__job--aside">Desarrollo <br/>& Testing de Software</h3>
                </div>

                {/* Navegación */}
                <nav className='navbar__nav'>
                    <ul className="nav__list--aside">
                        <li className="nav__item--aside" onClick={toggleAside}>
                            <BtnOptions btnName="INICIO" to="/"  />
                        </li>
                        <li className="nav__item--aside" onClick={toggleAside}>
                            <BtnOptions btnName="SOBRE MI" to="/about-me" />
                        </li>
                        <li className="nav__item--aside" onClick={toggleAside}>
                            <BtnOptions btnName="ESTUDIOS" to="/education" />
                        </li>
                        <li className="nav__item--aside" onClick={toggleAside}>
                            <BtnOptions btnName="EXPERIENCIA" to="/experience" />
                        </li>
                        <li className="nav__item--aside" onClick={toggleAside}>
                            <BtnOptions btnName="CONTACTO" to="/contact" />
                        </li>
                    </ul>
                </nav>

                {/*Links a redes sociales*/}
                <div className="personal-info__links--responsive">
                    <ul className="links__social--responsive">
                        <li className="social__option--responsive">
                            <a href="https://github.com/jporteiro2020" className="social__link" target='_blank' rel="noreferrer">
                                <i className="social__icon fa-brands fa-github"></i>
                            </a>
                        </li>

                        <li className="social__option--responsive">
                            <a href="https://www.linkedin.com/in/jonatan-porteiro/" className="social__link" target='_blank' rel="noreferrer">
                                <i className="social__icon fa-brands fa-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                </div>

                {/*Pie de página*/}
                <footer className="personal-info__footer--aside">
                    <p className='footer__Designer--responsive'>Web diseñada por:<a href="https://www.instagram.com/jessgrafico/" className='footer__social' target='_blank' rel="noreferrer"><i className='fa-brands fa-instagram'></i> JessGrafico</a></p>
                    <p className='footer__Developer--responsive'>Maquetada y desarrollada por:</p>
                    <p className='footer__copy--responsive'>
                        &copy; 2024 Jonatan Porteiro
                    </p>
                </footer>
            </section>
        </aside>
    );
}

export default AsideMenu;