import React from "react";

const Language = ({ classString, title }) => {
    return(
        <article className="lenguages__lenguage">
            <div className="lenguage__icon">
                <i className={ "icon__icon " + classString }></i>
            </div>

            <h3 className="lenguage__title">
                { title }
            </h3>
        </article>
    );
}

export default Language;