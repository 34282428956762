import './DownloadBtn.css';

const DownloadBtn = () => {

    const handleDownload = () => {
        // Ruta del backend para la descarga
        fetch('https://www.api.jonatanporteiro.uy/download')
            .then((response) => response.blob())
            .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'CV_Jonatan_Porteiro.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            })
            .catch((error) => console.error('Error al descargar el archivo:', error));
    };

    return(
        /* Botón del curriculum */
        <button className="download__button" id='download__button--banner' onClick={handleDownload}>
            DESCARGAR CV
        </button>
    );
}

export default DownloadBtn;