import React from "react";

const Program = ({ classString, title }) => {
    return(
        <>
            {
                classString === "fa-brands fa-git"
                ? <article className="programs__program">
                        <div className="program__icon">
                            <i className={ "icon__icon " + classString }></i>
                        </div>
                </article>
                : <article className="programs__program">
                        <div className="program__icon">
                            <i className={ "icon__icon " + classString }></i>
                        </div>

                        <h3 className="program__title">
                            { title }
                        </h3>
                </article>
            }
        </>
    );
}

export default Program;