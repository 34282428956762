import React from 'react';

const LoadingModal = ({ show }) => {
  if (!show) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <p style={styles.text}>Enviando mensaje...</p>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    width: '300px',
    padding: '20px',
    borderRadius: '8px',
    background: 'radial-gradient(circle at 70% center, #3a4bb7, #1a1b56, #050525)',
    color: '#fff',
    textAlign: 'center',
  },
  text: {
    fontSize: '18px',
  },
};

export default LoadingModal;