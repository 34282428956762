import React from "react";

import TimelineEducation from "../TimelineEducation";

import './education.css';
import './responsive.css';
import '../timelineCommon.css';
import '../timelineCommonResponsive.css';
import studies from './educationList.js';
import languages from './languagesList.js';

const Education = () => {
    return(
        <>
            <section className="content__education" key={1}>
                <header className="education__header">
                    <h2 className="education__title">
                        <span className="education__color">Est</span>udios
                    </h2>
                </header>

                <section className="timeline">
                    {
                        studies.map(function (study, index) {
                            return <TimelineEducation key={index} name={ study.name } university={ study.university } description={ study.description } date={ study.date } i={ 6 + index } />
                        })
                    }
                </section>
            </section>

            <section className="content__education--language" key={2}>
                <header className="education__header">
                    <h2 className="education__title">
                        <span className="education__color">Idi</span>omas
                    </h2>
                </header>

                <section className="timeline">
                    {
                        languages.map(function (language, index) {
                            return <TimelineEducation key={index} name={ language.name } university={ language.university } description={ language.description } date={ language.date } i={ 6 + index } />
                        })
                    }
                </section>
            </section>
        </>
    );
}

export default Education;