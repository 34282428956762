import React from "react";

import './timelineEducation.css';
import './responsive.css';

const TimelineEducation = ({ name, university, description, date, i }) => {

    return(
        <>
            <section className='timeline__content' key={ i }>
                <div className='content__data' key={ 20 + i }>
                    <p className="dataEducation__year"><span className="dataEducation__name">{ name }</span> : { date }</p>
                    <p className="data__university">Institución: <span className="university__name">{ university }</span></p>
                    <p className="dataEducation__description">Estado: { description }</p>
                </div>
            </section>
        </>
    );
}

export default TimelineEducation;