import React from 'react';
import { Outlet } from "react-router-dom";

import '../lib/styles/scroll.css';
import './common.css';
import './card.css';
import './responsive.css';

import DownloadBtn from "../lib/DownloadBtn";
import foto1 from '../../Recursos/default-user.jpeg';
import foto2 from '../../Recursos/default-user2.jpg';
import PatternCard from '../../Recursos/textura.png';

const Card = () => {
    return (
        /*Layout principal*/
        <div id='personal-info-card' className="layout__card">
            {/*Barra lateral (información)*/}
            <aside className="card__aside">
                <section className="aside__personal-info">
                    {/*Información general*/}
                    <div className="personal-info__container-image">
                        <img src={ foto1 } alt="Foto de Jonatan Porteiro" className="personal-info__image personal-info__image-front" />
                        <img src={ foto2 } alt="Foto de Jonatan Porteiro" className="personal-info__image personal-info__image-back"/>
                    </div>
                    
                    <div className="personal-info__general">
                        <h1 className="personal-info__name">Jonatan Porteiro</h1>
                        <h2 className="personal-info__job">Desarrollador & Tester de Software</h2>
                    </div>

                    {/*Links a redes sociales*/}
                    <div className="personal-info__links">
                        <ul className="links__social">
                            <li className="social__option">
                                <a href="https://github.com/jporteiro2020" className="social__link" target='_blank' rel="noreferrer">
                                    <i className="social__icon fa-brands fa-github"></i>
                                </a>
                            </li>

                            <li className="social__option">
                                <a href="https://www.linkedin.com/in/jonatan-porteiro/" className="social__link" target='_blank' rel="noreferrer">
                                    <i className="social__icon fa-brands fa-linkedin"></i>
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/*Botón del curriculum*/}
                    <div className="personal-info__buttons">
                        <DownloadBtn />
                    </div>

                    {/*Pie de página*/}
                    <footer className="personal-info__footer">
                        <p className='footer__Designer'>Web diseñada por:<a href="https://www.instagram.com/jessgrafico/" className='footer__social' target='_blank' rel="noreferrer"><i className='fa-brands fa-instagram'></i> JessGrafico</a></p>
                        <p className='footer__Developer'>Maquetada y desarrollada por:</p>
                        <p className='footer__copy'>
                            &copy; 2024 Jonatan Porteiro
                        </p>
                    </footer>
                </section>
            </aside>

            {/*Contenido principal*/}
            <main className="card__content">
                <section className="content__page">
                    <Outlet />
                </section>
                <img src={ PatternCard } alt="Imagen qcon líneas rectas formando un patrón" className='content__pattern' />
            </main>
        </div>
    );
}

export default Card;