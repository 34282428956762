import React from 'react';
import './banner.css';
import './responsive.css';
import Pattern from '../../Recursos/fondo-textura.png';
import Computer from '../../Recursos/computadora.png';
import Cup from '../../Recursos/taza.png';
import Tablet from '../../Recursos/tablet.png';
import Image1 from '../../Recursos/iconos-generales_1.png';
import Image2 from '../../Recursos/iconos-generales_2.png';
import Image3 from '../../Recursos/iconos-generales_3.png';
import Image4 from '../../Recursos/iconos-generales_4.png';
import Image5 from '../../Recursos/iconos-generales_5.png';
import Image6 from '../../Recursos/iconos-generales_6.png';
import Image7 from '../../Recursos/iconos-generales_7.png';
import Image8 from '../../Recursos/iconos-generales_8.png';

import DownloadBtn from '../lib/DownloadBtn';

const Banner = () => {
    return (
        <section id="bannerSection" className="navbar__banner">
            <div className="banner__content">
                <h1 className="banner__title">Desarrollo <br/>& Testing de Software</h1>
                <h2 className='banner__subtitle'>By Jonatan Porteiro</h2>
                
				<DownloadBtn />
            </div>
            <img src={Pattern} alt="Imagen con líneas rectas formando un patrón" className='banner__pattern' />
            {/* Imágenes del banner */}
            <div className="banner__images">
                <div className="image__item image__item--1">
                    <img src={Computer} alt="Imagen de una computadora" className="image" />
                </div>

                <div className="image__item image__item--2">
                    <img src={Cup} alt="Imagen de una taza de café" className="image" />
                </div>

                <div className="image__item image__item--3">
                    <img src={Tablet} alt="Imagen de una tablet" className="image" />
                </div>

                <div className="image__item image__item--4">
                    <img src={Image1} alt="Imagen botón de descarga" className="image" />
                </div>
                    
                <div className="image__item image__item--5">
                    <img src={Image2} alt="Imagen candado (Representa la seguridad de las aplicaciones)" className="image" />
                </div>

                <div className="image__item image__item--6">
                    <img src={Image3} alt="Imagen que representa una alerta" className="image" />
                </div>

                <div className="image__item image__item--7">
                    <img src={Image4} alt="Imagen de una nube" className="image" />
                </div>

                <div className="image__item image__item--8">
                    <img src={Image5} alt="Imagen de una etiqueta DIV" className="image" />
                </div>

                <div className="image__item image__item--9">
                    <img src={Image6} alt="Imagen de una etiqueta <React.Fragmento>" className="image" />
                </div>

                <div className="image__item image__item--10">
                    <img src={Image7} alt="La imagen contiene las letras JS (JavaScript)" className="image" />
                </div>

                <div className="image__item image__item--11">
                    <img src={Image8} alt="Imagen de corchete de apertura y cierre" className="image" />
                </div>
            </div>
        </section>
    );
}

export default Banner;