import React from 'react';

import './aboutMe.css';
import './responsive.css';

import PersonalData from './PersonalData/PersonalData';
import Language from './Language';
import DataBase from './DataBase';
import Program from './Program';

const AboutMe = () => {

    return (
        <>
            <section className="content__about" key={ 1 }>
                <header className="about__header">
                    <h2 className="about__title">
                        Sobre <span className="about__color">Mi</span>
                    </h2>
                </header>

                <section className="about__personal-info" key={ 2 }>
                    <article className="personal-info__bio">
                        <p className="info__bio__p info__bio__p--1">
                            ¡Hola! Soy Jonatan Porteiro.
                        </p>

                        <p className="info__bio__p info__bio__p--2">
                            Cuento con casi 8 años de experiencia como Tester de Software. He tenido y tengo la oportunidad de llevar adelante proyectos de testing,
                            trabajando en conjunto con algunos de los principales clientes de las empresas en las cuales he colaborado y sigo colaborando.
                        </p>

                        <p className="info__bio__p info__bio__p--3">
                            Estoy terminando de cursar los últimos semestres de la carrera de Analista en Tecnologías de la Información de la Universidad ORT,
                            por lo cual ya cuento con el título intermedio de Programador Web.
                            Adicionalmente, he hecho otros cursos, como por ejemplo: Programación fullstack, Programación Ruby, reparación PC y redes, entre otros.
                            Me encanta aprender, y es una de las razones por la cual me gusta la informática, porque todos los días se puede y se debe aprender algo nuevo.
                        </p>

                        <p className="info__bio__p info__bio__p--4">
                            Si están interesados en trabajar conmigo sientánse libres de contactarme!
                        </p>
                    </article>

                    <article className="personal-info__data">
                        <ul className="personal-info__list">
                            <PersonalData classString="personal-info__option--first" title="Edad:"  value="" />
                            <PersonalData classString="" title="País:"  value="Uruguay" />
                            <PersonalData classString="" title="Correo:"  value="jonatan.porteiro@gmail.com" />
                        </ul>
                    </article>
                </section>

                <section className="about__skills" key={ 3 }>
                    <header className="skills__header">
                        <h2 className="skills__title">
                            Habilidades
                        </h2>
                    </header>

                    <section className="skills__lenguages">
                        <header className="lenguages__header">
                            <h2 className="lenguages__title">
                                Lenguajes, Librerías y Frameworks
                            </h2>
                        </header>

                        <div className="lenguages__container">
                            <Language classString="fa-brands fa-js" title="JavaScript" />

                            <Language classString="fa-brands fa-java" title="Java" />

                            <Language classString="fa-solid fa-laptop-code" title="C#" />

                            <Language classString="fa-brands fa-node-js" title="NodeJS" />

                            <Language classString="fa-brands fa-react" title="React" />

                            <Language classString="fa-brands fa-html5" title="HTML5" />

                            <Language classString="fa-brands fa-css3-alt" title="CSS3" />

                            <Language classString="fa-brands fa-bootstrap" title="Bootstrap" />

                            <Language classString="fa-solid fa-laptop-code" title="OnsenUI" />

                            <Language classString="fa-solid fa-laptop-code" title="Apache Cordova" />
                        </div>
                    </section>

                    <section className="skills__databases" key={ 4 }>
                        <header className="databases__header">
                            <h2 className="databases__title">
                                Base de datos
                            </h2>
                        </header>

                        <div className="databases__container">
                            <DataBase title="Oracle" />
                            <DataBase title="PostgreSQL" />
                            <DataBase title="SQL Server" />
                        </div>
                    </section>

                    <section className="skills__programs" key={ 5 }>
                        <header className="programs__header">
                            <h2 className="programs__title">
                                Programas
                            </h2>
                        </header>

                        <div className="programs__container">
                            <Program classString="fa-brands fa-git" title="" />

                            <Program classString="fa-solid fa-code" title="NetBeans" />

                            <Program classString="fa-solid fa-brain" title="IntelliJ IDEA" />

                            <Program classString="fa-solid fa-file-code" title="Visual Studio" />

                            <Program classString="fa-solid fa-file" title="Visual Studio Code" />

                            <Program classString="fa-solid fa-gears" title="SoapUI" />

                            <Program classString="fa-solid fa-gears" title="Postman" />
                        </div>
                    </section>
                </section>
            </section>
        </>
    );
}

export default AboutMe;