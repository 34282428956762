import React, {useState} from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import '../lib/fonts/fontawesome-free-6.1.2-web/css/all.min.css';
import '../lib/styles/reset.css';
import '../lib/fonts/fonts.css';
import '../lib/styles/base.css';

import Header from "../Header";
import AsideMenu from "../AsideMenu";
import Banner from "../Banner";
import Card from "../Card";
import AboutMe from "../Card/AboutMe";
import Education from "../Card/Education";
import Experience from "../Card/Experience";
import Contact from "../Card/Contact";

function App() {
  const [isAsideVisible, setIsAsideVisible] = useState(false);

  // Función para alternar la visibilidad
  const toggleAside = () => {
    setIsAsideVisible(prev => !prev);
  };

  return (
    <>
      <Router>
        {isAsideVisible && <AsideMenu toggleAside={ toggleAside } />}
        
        <Header isAsideVisible={ isAsideVisible } toggleAside={ toggleAside } />
        <Routes>
          <Route path="/" element={<Banner />} />
          <Route path="about-me" element={<Card />}>
            <Route index element={<AboutMe />} />
          </Route>
          <Route path="education" element={<Card />}>
            <Route index element={<Education />} />
          </Route>
          <Route path="experience" element={<Card />}>
            <Route index element={<Experience />} />
          </Route>
          <Route path="contact" element={<Card />}>
            <Route index element={<Contact />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
