import React from "react";

import TimelineExperience from "../TimelineExperience";
import experiences from './timelineExperiences.js';

import './experience.css';
import '../timelineCommon.css';
import '../timelineCommonResponsive.css';

const Experience = () => {
    return(
        <>
            <section className="content__experience" key={ 20 } >
                <header className="experience__header">
                    <h2 className="experience__title">
                        <span className="experience__color">Exp</span>eriencia
                    </h2>
                </header>

                <section className="timeline" key={ 21 } >
                    {
                        experiences.map(function (experience, index) {
                            index = index + 21;
                            return <TimelineExperience key={ index } position={ experience.position } company={ experience.company } tasks={ experience.tasks } startDate={ experience.startDate }  endDate={ experience.endDate } i={ index } />
                        })
                    }
                </section>
            </section>
        </>
    );
}

export default Experience;