import React from "react";

const DataBase = ({ title }) => {
    return(
        <article className="databases__database">
            <div className="database__icon">
                <i className="icon__icon fa-solid fa-database"></i>
            </div>

            <h3 className="database__title">
                { title }
            </h3>
        </article>
    );
}

export default DataBase;