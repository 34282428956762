import React from "react";
import { Link } from "react-router-dom";

const BtnOptions = ({ btnName, to }) => {
    /* const handleScroll = () => {
        const cardElement = document.getElementById('personal-info-card'); // Obtener el elemento Card por su ID
        cardElement.scrollIntoView({ behavior: 'smooth' }); // Desplazarse suavemente al elemento Card
    }; */

    return(
        <Link to={ to } preventScrollReset={ true } /* onClick={ handleScroll } */ className="nav__btn">
            { btnName }
        </Link>
    );
}

export default BtnOptions;